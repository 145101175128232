@use "@mit-45/brand/fonts/neue-haas-grotesk" as neue-haas;
.container {

  .title {
    @include neue-haas.display-r-76-bold-italic;
  }
  .content {
    @include neue-haas.display-r-55-roman;
  }
  .actions {
    @include neue-haas.display-r-65-medium;
  }
}
