@use "@mit-45/brand-responsive/breakpoint";
@use "@mit-45/brand-responsive/orientation";
@use "@mit-45/brand-responsive/container";
@use "@mit-45/brand/fonts/neue-haas-grotesk" as neue-haas;

.container {
  @include container.make-page-container;
  width: unset;
  padding: 30px;


  .details {
    @include breakpoint.mobile {

    }

    @include breakpoint.tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 25px;
      row-gap: 5px;
      justify-items: stretch;
      align-items: center;
      justify-content: stretch;
    }

    @include breakpoint.desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 35px;
      row-gap: 15px;
      justify-items: stretch;
      align-items: stretch;
      justify-content: stretch;
    }
  }


  .agree {
    padding-top: 20px;
    padding-bottom: 15px;
    margin-left: 50px;
    margin-right: 50px;

    .agreeLabel {
      display: flex;
      align-items: flex-start;


      .agreeText {
        padding-top: 9px;
        font-size: 12px;
        @include neue-haas.text-55-roman();
      }
    }

  }

  .actions {
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;

    %actionButtons {
      padding-left: 50px;
      padding-right: 50px;
      text-transform: uppercase;
      @include neue-haas.display-r-76-bold-italic();
    }

    .actionPrimary {
      // submit button
      @extend %actionButtons;



      border-color: #191718;
      background-color: #191718;

      color: #C4D1B2;

    }

    .actionSecondary {
      // reset button
      @extend %actionButtons;
      border-color: #191718;
      color: #191718;


    }

  }
}
