
.container {
  height: 100%;
  max-height: 70vh;
  overflow-y: hidden;
  .spinWheel {
    width: 100%;
    max-width: 750px;
    height: 70vh;
    margin-left: auto;
    margin-right: auto;

  }
}
