@use "@mit-45/brand/fonts/neue-haas-grotesk" as neue-haas;
.container {

  .status {
    @include neue-haas.display-r-76-bold-italic;

  }
  .config {
    @include neue-haas.display-r-65-medium;
  }
}
