@use "@mit-45/brand/fonts/neue-haas-grotesk" as neue-haas;
.container {
  .inputGroup {
    margin-bottom: 8px;
    .inputLabel {
      @include neue-haas.display-r-55-roman;
      .inputText {
        margin-left: 10px;
        @include neue-haas.text-55-roman;
      }
    }
  }

  .inputActionGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
