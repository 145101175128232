
.container {
  display: flex;
  justify-content: space-around;

  .switches {

  }

}
