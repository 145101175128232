%base-container {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 25px;

  .title {

  }
}

.winContainer {
  @extend %base-container;

}
.infoContainer {
  @extend %base-container;

}
.sorryContainer {
  @extend %base-container;
}
