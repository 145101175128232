@use "@mit-45/brand/fonts/neue-haas-grotesk" as neue-haas;

.detail {
  padding-top: 5px;
  padding-bottom: 20px;

  .labelWrapper {
    display: block;

    .label {
      @include neue-haas.display-66-medium-italic();
      padding-bottom: 10px;
    }

    .inputWrapper {
      padding-top: 0;
      padding-right: 10px;
      padding-left: 0;
      padding-bottom: 0;

      .input {
        margin: 0;
        width: 100%;
        @include neue-haas.text-55-roman();
      }
    }
  }
}
