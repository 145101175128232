.container {
  //width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .changeTypeContainer {
  }

  .changeFormContainer {

  }
}
