@use "@mit-45/brand-responsive/breakpoint";
@use "@mit-45/brand-responsive/orientation";
@use "@mit-45/brand-responsive/container";

.container {
  .topBar {
    //@include container.make-page-container;
    .mit45Logo {
      padding-top: 10px;
      padding-left: 10px;
      height: 72px;
      width: auto;
    }
    margin-bottom: 20px;
  }

  .info {
    @include container.make-page-container;

    margin-bottom: 30px;

    @include breakpoint.mobile {
      text-align: center;
    }
    @include breakpoint.tablet-to-desktop {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }


    %cta-image {
      height: auto;

      @include breakpoint.mobile {
        width: 75%;
      }
      @include breakpoint.tablet-to-desktop {
        width: 276px;
      }
    }
    .savingsImage {
      @extend %cta-image;
    }
    .andWrapper {
      text-align: center;

      @include breakpoint.tablet-to-desktop {
        width: 142px;
        overflow-x: hidden;
      }

      .andImage {
        height: 50px;
        width: auto;

        @include breakpoint.tablet-to-desktop {
          margin-left: -95px;
        }
      }
    }
    .spinToWinImage {
      @extend %cta-image;

    }
  }
}
